import React, { Fragment, Component } from 'react'
import { Button, Modal, Form, Header } from 'semantic-ui-react'

class Manufacturers extends Component {
    initState = () => {
        const { object } = this.props
        let inputs = {}

        Object.keys(object).forEach(key => { inputs[key] = object[key] ? object[key] : "" })

        return inputs
    }

    getEdited = () => {
        const { object } = this.props
        const { inputs } = this.state
        let edited = {}

        Object.keys(inputs).forEach(key => {
            if (inputs[key] !== object[key]) {
                edited[key] = inputs[key]
            }
        })

        return edited
    }

    getOriginalEdited = () => {
        const { object } = this.props
        const edited = this.getEdited()
        const original = {}

        Object.keys(edited).forEach(key => { original[key] = object[key] })

        return original
    }

    state = {
        inputs: this.initState(),
        modalOpen: false,
    }

    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => this.setState({ modalOpen: false, inputs: this.initState() })

    render() {
        const { object, header } = this.props
        const { inputs, modalOpen } = this.state

        return (
            <Fragment>
                <Button primary content="Edit" onClick={this.handleOpen} />
                <Modal open={modalOpen} onClose={this.handleClose} >
                    <Modal.Header>Edit: {header}</Modal.Header>
                    <Modal.Content>
                        <Form>
                            {Object.keys(object).map(key =>
                                <Form.Input
                                    key={key}
                                    label={key}
                                    value={inputs[key]}
                                    onChange={e => this.setState({ inputs: { ...inputs, [key]: e.target.value } })}
                                />
                            )}
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Modal trigger={<Button color="vk" content="See changes" />} >
                            <Modal.Content>
                                <Header as="h3" content="Edited (will be sent to API)" />
                                <pre>
                                    {JSON.stringify(this.getEdited(), null, 4)}
                                </pre>
                                <Header as="h3" content="Original" />
                                <pre>
                                    {JSON.stringify(this.getOriginalEdited(), null, 4)}
                                </pre>
                            </Modal.Content>
                        </Modal>
                        <Button content="Cancel" onClick={this.handleClose} />
                        <Button color="green" content="Save" onClick={() => {
                            this.props.onSave(this.getEdited())
                            this.handleClose()
                        }} />
                    </Modal.Actions>
                </Modal>
            </Fragment>
        )
    }
}

export default Manufacturers
