import React, { Fragment, Component } from 'react'
import { Button, Message } from 'semantic-ui-react'

import { flush, flushAll } from '../../../api/services/admin'

class Flush extends Component {
    state = {
        loadingFlush: false,
        loadingFlushAll: false,
        showMessage: false,
        error: null,
    }

    showMessage = () => {
        this.setState({ showMessage: true }, () => {
            setTimeout(() => this.setState({ showMessage: false }), 3000)
        })
    }

    flush = () => {
        this.setState({ loadingFlush: true })
        flush()
            .then(res => {
                if (res.status === 200) {
                    this.showMessage()
                } else {
                    this.setState({ error: res })
                }
            })
            .catch(e => this.setState({ error: e }))
            .then(() => this.setState({ loadingFlush: false }))
    }

    flushAll = () => {
        this.setState({ loadingFlushAll: true })
        flushAll()
            .then(res => {
                if (res.status === 200) {
                    this.showMessage()
                } else {
                    this.setState({ error: res })
                }
            })
            .catch(e => this.setState({ error: e }))
            .then(() => this.setState({ loadingFlushAll: false }))
    }

    render() {
        const { loadingFlush, loadingFlushAll, showMessage, error } = this.state
        return (
            <Fragment>
                <Button.Group widths="2" >
                    <Button secondary content="Flush" loading={loadingFlush} disabled={loadingFlush} onClick={this.flush} />
                    <Button primary content="Flush all" loading={loadingFlushAll} disabled={loadingFlushAll} onClick={this.flushAll} />
                </Button.Group>
                {showMessage && <Message success content="Flushed!" />}
                {error && <Message error content={<pre>{JSON.stringify(error, null, 4)}</pre>} />}
            </Fragment>
        )
    }
}

export default Flush
