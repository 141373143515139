import request from '../request';

export const getManufacturers = () => request({
    url: '/manufacturer',
    method: 'GET',
});

export const updateManufacturer = (guid, data) => request({
    url: `/manufacturer/${guid}`,
    method: 'PATCH',
    data,
});

export const addManufacturer = data => request({
    url: '/manufacturer',
    method: 'POST',
    data,
});

export const setManufacturer = guid => request({
    url: `/manufacturer/set-current?guid=${guid}`,
    method: 'POST',
});

