import React, { Fragment, Component } from 'react'
import { Table } from 'semantic-ui-react'

// import AddModal from '../components/AddModal'
import Page from '../components/Page'

import { getModules } from '../../../api/services/module'

class Modules extends Component {
    state = {
        modules: [],
        filteredList: [],
        loading: false,
        error: null,
    }

    componentDidMount() {
        this.getModules()
    }

    getModules = () => {
        this.setState({ loading: true })
        getModules()
            .then(res => this.setState({ modules: res.data, filteredList: res.data }))
            .catch(e => this.setState({ error: e }))
            .then(() => this.setState({ loading: false }))
    }

    render() {
        const { filteredList, loading, error } = this.state

        return (
            <Page
                icon="box"
                name="Modules"
                length={filteredList.length}
                loading={loading}
                error={error}
                tableHeaders={["Module", "Description"]}
                tableBody={() => (
                    <Fragment>
                        {filteredList.map(module =>
                            <Table.Row key={module.module} >
                                <Table.Cell>{module.module}</Table.Cell>
                                <Table.Cell>{module.description}</Table.Cell>
                            </Table.Row>
                        )}
                    </Fragment>
                )}
            // addNew={() => (
            //     <AddModal
            //         header="module"
            //         fields={["name",]}
            //         onSave={() => window.alert("hmm")}
            //     />
            // )}
            />
        )
    }
}

export default Modules
