import React, { Component } from 'react'
import { Segment, Header, Grid, Button } from 'semantic-ui-react'

import Flush from '../components/Flush'
import ExternalLinks from './ExternalLinks'

import { getManufacturers } from '../../../api/services/manufacturer'
import { userSearch } from '../../../api/services/user'
import Reindex from '../components/Reindex';

const Card = ({ header, icon, loading, color, children }) => (
    <Segment loading={loading} color={color} >
        <Grid columns="equal" >
            <Grid.Column width="10">
                <Header
                    as="h3"
                    icon={icon}
                    content={header}
                />
                <div style={{ marginLeft: 44, marginTop: 32 }} >
                    {children}
                </div>
            </Grid.Column>
            <Grid.Column textAlign="right" >
                <Button primary content="Edit" />
            </Grid.Column>
        </Grid>
    </Segment>
)

const Line = ({ content, color }) => <Header as="h4" color={color} content={content} />

class Dashboard extends Component {
    state = {
        manufacturers: null,
        loadingManufacturers: false,
        servers: null,
        loadingServers: false,
        users: null,
        loadingUsers: false,
        apiUsers: null,
        loadingApiUsers: false,
    }

    componentDidMount() {
        this.getManufacturers()
        this.getUsers()
    }

    getManufacturers = () => {
        this.setState({ loadingManufacturers: true })
        getManufacturers()
            .then(res => this.setState({ manufacturers: res.data }))
            .then(() => this.setState({ loadingManufacturers: false }))
    }

    getUsers = () => {
        this.setState({ loadingUsers: true, loadingApiUsers: true })
        userSearch()
            .then(res => this.setState({ users: res.data, apiUsers: res.data.filter(user => user.apiUserId) }))
            .then(() => this.setState({ loadingUsers: false, loadingApiUsers: false }))
    }

    render() {
        const { manufacturers, loadingManufacturers, servers, loadingServers, users, loadingUsers, apiUsers, loadingApiUsers } = this.state

        const activeManufacturers = manufacturers ? manufacturers.filter(man => man.enabled === undefined || man.enabled === 1).length : '-'
        const inactiveManufacturers = manufacturers ? manufacturers.filter(man => man.enabled === 0).length : '-'

        const serversUp = servers ? servers.length : '-'
        const serversDown = servers ? servers.length : '-'

        const activeUsers = users ? users.filter(user => !user.blocked).length : '-'
        const inactiveUsers = users ? users.filter(user => user.blocked).length : '-'

        const activeApiUsers = apiUsers ? apiUsers.filter(user => user.apiEnabled).length : '-'
        const inactiveApiUsers = apiUsers ? apiUsers.filter(user => !user.apiEnabled).length : '-'

        return (
            <Grid stackable >
                <Grid.Row>
                    <Grid.Column width="8" >
                        <Card header="Manufacturers" icon="industry" loading={loadingManufacturers} color="red" >
                            <Line content={`${activeManufacturers} active Manufacturers`} />
                            <Line content={`${inactiveManufacturers} inactive Manufacturers`} />
                        </Card>
                    </Grid.Column>
                    <Grid.Column width="8" >
                        <Card header="CalWin Servers" icon="globe" loading={loadingServers} color="blue" >
                            <Line content={`${serversUp} servers: UP`} />
                            <Line content={`${serversDown} servers: DOWN`} color={serversDown > 0 ? "red" : ""} />
                        </Card>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width="8" >
                        <Card header="Users" icon="users" loading={loadingUsers} color="green" >
                            <Line content={`${activeUsers} active Users`} />
                            <Line content={`${inactiveUsers} inactive Users`} />
                        </Card>
                    </Grid.Column>
                    <Grid.Column width="8" >
                        <Card header="API Users" icon="users" loading={loadingApiUsers} color="purple" >
                            <Line content={`${activeApiUsers} active API Users`} />
                            <Line content={`${inactiveApiUsers} inactive API Users`} />
                        </Card>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column textAlign="center" >
                        <Segment color="black" >
                            <Grid stackable columns="equal" >
                                <Grid.Row>
                                    <Grid.Column>
                                        <Flush />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Reindex />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column><ExternalLinks hideMenu={true} /></Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default Dashboard
