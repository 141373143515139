import React, { Fragment } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from 'semantic-ui-react'

import { getToken } from '../../../helpers/auth'

const Swagger = () => {
    const token = getToken()

    const getUrl = () => {
        const environment = localStorage.getItem("environment")

        if (environment) {
            if (environment === "local") return "http://localhost:3000"
            if (environment === "dev") return "https://api-dev.octaos.com"
            if (environment === "stg") return "https://api-stg.octaos.com"
            if (environment === "prod") return "https://api.octaos.com"
        } else {
            return "https://osc-api-srv-dev.azurewebsites.net"
        }
    }

    return (
        <Fragment>
            <CopyToClipboard text={token} >
                <Button primary content="Copy token" />
            </CopyToClipboard>
            <iframe title="api" src={`${getUrl()}/docs`} style={{ border: 0, width: '100%', height: '78vh' }} />
        </Fragment>
    )
}

export default Swagger
