import request from '../request';

export const flush = () => request({
    url: '/admin/cache/flush',
    method: 'POST',
});

export const flushAll = () => request({
    url: '/admin/cache/flush-all',
    method: 'POST',
});

