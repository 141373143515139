import Cookies from 'js-cookie';

/**
 * Stores user token in cookies
 * @param {String} token - User JWT token
 */
export const setToken = (token) => {
  Cookies.set('token', token, { expires: 30 });
};

/**
 * Checks if user is authenticated
 * @returns {boolean}
 */
export const isAuthenticated = () => !!Cookies.get('token');

/**
 * Removes JWT token from cookies
 */
export const deleteToken = () => {
  Cookies.remove('token');
};

/**
 * Gets user JWT token
 * @returns {String} - JWT token
 */
export const getToken = () => Cookies.get('token');
