import request from '../request';

export const getReports = () => request({
    url: '/report',
    method: 'GET',
});

export const getReportInfo = name => request({
    url: `/report/${name}`,
    method: 'GET',
});

export const getReport = (name, data) => request({
    url: `/report/${name}`,
    method: 'POST',
    data,
});
