import React, { Fragment, Component } from 'react'
import { Button, Modal, Form, } from 'semantic-ui-react'

import { getReports } from '../../../../api/services/report'
import { setManufacturer } from '../../../../api/services/manufacturer'

class AddModal extends Component {
    state = {
        modalOpen: false,
        manufacturers: [],
        loadingManufacturers: false,
        loadingReports: false,
        error: null,
        selectedGuid: null,
        reportName: "",
        reports: [],
        selectedReport: null,
    }

    getReports = () => {
        this.setState({ loadingReports: true, reports: [], selectedReport: null })
        getReports()
            .then(res => this.setState({ reports: res.data }))
            .catch(error => this.setState({ error }))
            .then(() => this.setState({ loadingReports: false }))
    }

    handleGuidChange = async value => {
        await setManufacturer(value).catch(error => this.setState({ error }))
        this.getReports()
        this.setState({ selectedGuid: value })
    }

    getCurrentManufacturerGuid = () => {
        const manufacturers = this.props.manufacturers
        const local = localStorage.getItem("currentManufacturer")
        const name = local ? local : "Demo"

        const man = manufacturers.find(manu => manu.name === name)

        return man.guid
    }

    handleOpen = () => {
        this.setState({ modalOpen: true })
        if (this.props.manufacturers.length !== 0) {
            this.setState({ selectedGuid: this.getCurrentManufacturerGuid() }, () => this.getReports())
        }
    }

    handleClose = async () => {
        await setManufacturer(this.getCurrentManufacturerGuid()).catch(error => this.setState({ error }))
        this.setState({ modalOpen: false })
    }

    formValid = () => {
        const { selectedGuid, reportName, selectedReport } = this.state
        return selectedGuid && reportName && selectedReport
    }

    render() {
        const { modalOpen, error, selectedGuid, reports, selectedReport, loadingReports, reportName } = this.state
        const { manufacturers, loadingManufacturers } = this.props

        return (
            <Fragment>
                <Button primary content="Add new" onClick={this.handleOpen} floated="right" />
                <Modal open={modalOpen} onClose={this.handleClose} >
                    <Modal.Header>Add CalWin report</Modal.Header>
                    <Modal.Content>
                        {error
                            ?
                            <pre>{JSON.stringify(error, null, 4)}</pre>
                            :
                            <Form>
                                <Form.Group widths="equal" >
                                    <Form.Input
                                        required
                                        label="Name"
                                        value={reportName}
                                        onChange={e => this.setState({ reportName: e.target.value })}
                                    />
                                    <Form.Dropdown
                                        required
                                        scrolling
                                        search
                                        selection
                                        label="Manufacturer"
                                        placeholder="Select manufacturer"
                                        loading={loadingManufacturers}
                                        options={manufacturers.filter(man => man.guid).map(man => ({ key: man.guid, text: man.name, value: man.guid }))}
                                        value={selectedGuid}
                                        onChange={(_, { value }) => this.handleGuidChange(value)}
                                    />
                                    <Form.Dropdown
                                        required
                                        scrolling
                                        search
                                        selection
                                        label="Report"
                                        placeholder="Select report"
                                        loading={loadingReports}
                                        options={reports.map(report => ({ key: report, text: report, value: report }))}
                                        value={selectedReport}
                                        onChange={(_, { value }) => this.setState({ selectedReport: value })}
                                    />
                                </Form.Group>
                                {/* <Modal.Content scrolling>
                                    {loadingReports
                                        ?
                                        <Placeholder>
                                            {Array.from(Array(60).keys()).map(x =>
                                                <Placeholder.Line key={x} />
                                            )}
                                        </Placeholder>
                                        :

                                        <List animated selection verticalAlign="middle" >
                                            {reports.map(report =>
                                                <List.Item
                                                    key={report}
                                                    active={report === selectedReport}
                                                    onClick={() => this.setState({ selectedReport: report })}
                                                >
                                                    <List.Header>{report}</List.Header>
                                                </List.Item>
                                            )}
                                        </List>
                                    }
                                </Modal.Content> */}
                            </Form>
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button content="Cancel" onClick={this.handleClose} />
                        <Button
                            disabled={!this.formValid()}
                            color="green"
                            content="Save"
                            onClick={() => {
                                this.props.onSave(reportName, selectedGuid, selectedReport)
                                this.handleClose()
                            }} />
                    </Modal.Actions>
                </Modal>
            </Fragment>
        )
    }
}

export default AddModal
