import React, { Fragment, Component } from 'react'
import { Table } from 'semantic-ui-react'

import EditModal from '../components/EditModal'
import AddModal from '../components/AddModal'
import Page from '../components/Page'

import { getGroups } from '../../../api/services/userGroup'

class UserGroups extends Component {
    state = {
        groups: [],
        filteredList: [],
        loading: false,
        error: null,
    }

    componentDidMount() {
        this.getGroups()
    }

    getGroups = () => {
        this.setState({ loading: true })
        getGroups()
            .then(res => this.setState({ groups: res.data, filteredList: res.data }))
            .catch(e => this.setState({ error: e }))
            .then(() => this.setState({ loading: false }))
    }

    render() {
        const { filteredList, loading, error } = this.state

        return (
            <Page
                icon="users"
                name="User Groups"
                length={filteredList.length}
                loading={loading}
                error={error}
                tableHeaders={["ID", "User Group", "Users in group", "Enabled", ""]}
                tableBody={() => (
                    <Fragment>
                        {filteredList.map(group =>
                            <Table.Row key={group.id} >
                                <Table.Cell>{group.id}</Table.Cell>
                                <Table.Cell>{group.name}</Table.Cell>
                                <Table.Cell>(roles) {group.roles.length}</Table.Cell>
                                <Table.Cell>{group.enabled ? "Yes" : "No"}</Table.Cell>
                                <Table.Cell collapsing>
                                    <EditModal
                                        object={group}
                                        header={group.name}
                                        onSave={inputs => this.handleSave(group.id, inputs)}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Fragment>
                )}
                addNew={() => (
                    <AddModal
                        header="user group"
                        fields={["name",]}
                        onSave={() => window.alert("hmm")}
                    />
                )}
            />
        )
    }
}

export default UserGroups

