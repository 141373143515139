import React, { Fragment, Component } from 'react'
import { Button, Modal, Form } from 'semantic-ui-react'

class AddModal extends Component {
    initState = () => {
        const { fields } = this.props
        let inputs = {}

        fields.forEach(key => { inputs[key] = "" })

        return inputs
    }

    state = {
        inputs: this.initState(),
        modalOpen: false,
    }

    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => this.setState({ modalOpen: false, inputs: this.initState() })

    formValid = () => {
        const { inputs } = this.state

        return Object.keys(inputs).every(input => inputs[input] !== "")
    }

    render() {
        const { fields, header } = this.props
        const { inputs, modalOpen } = this.state

        return (
            <Fragment>
                <Button primary content="Add new" onClick={this.handleOpen} floated="right" />
                <Modal open={modalOpen} onClose={this.handleClose} >
                    <Modal.Header>Add {header}</Modal.Header>
                    <Modal.Content>
                        <Form>
                            {fields.map(key =>
                                <Form.Input
                                    required
                                    key={key}
                                    label={key}
                                    value={inputs[key]}
                                    onChange={e => this.setState({ inputs: { ...inputs, [key]: e.target.value } })}
                                />
                            )}
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Modal trigger={<Button color="vk" content="Preview" />} >
                            <Modal.Content>
                                <pre>
                                    {JSON.stringify(inputs, null, 4)}
                                </pre>
                            </Modal.Content>
                        </Modal>
                        <Button content="Cancel" onClick={this.handleClose} />
                        <Button
                            disabled={!this.formValid()}
                            color="green"
                            content="Save"
                            onClick={() => {
                                this.props.onSave(inputs)
                                this.handleClose()
                            }} />
                    </Modal.Actions>
                </Modal>
            </Fragment>
        )
    }
}

export default AddModal
