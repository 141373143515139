import React, { Fragment, Component } from 'react'
import { Select, Input, Button, TextArea, Form } from 'semantic-ui-react'
import axios from 'axios'
import ReactJson from 'react-json-view'

const getUrl = () => {
    const environment = localStorage.getItem("environment")

    if (environment) {
        if (environment === "local") return "http://localhost:3000"
        if (environment === "dev") return "https://api-dev.octaos.com"
        if (environment === "stg") return "https://api-stg.octaos.com"
        if (environment === "prod") return "https://api.octaos.com"
    } else {
        return "https://api-dev.octaos.com"
    }
}

const options = [
    { key: 'local', text: 'local', value: 'http://localhost:3000' },
    { key: 'dev', text: 'dev', value: 'https://api-dev.octaos.com' },
    { key: 'stg', text: 'stg', value: 'https://api-stg.octaos.com' },
    { key: 'prod', text: 'prod', value: 'https://api.octaos.com' },
]

const methodOptions = [
    { key: 'GET', text: 'GET', value: 'GET' },
    { key: 'POST', text: 'POST', value: 'POST' },
    { key: 'PUT', text: 'PUT', value: 'PUT' },
    { key: 'DELETE', text: 'DEL', value: 'DELETE' },
]

const getDataList = () => {
    const local = localStorage.getItem("devToolsDataList")

    if (local)
        return JSON.parse(local)
    else
        return []
}

class DevTools extends Component {
    state = {
        path: "",
        baseUrl: getUrl(),
        method: "GET",
        data: "{\n\n}",
        loading: false,
        result: null,
        error: null,
        dataList: getDataList()
    }

    addToDataList = value => {
        const { dataList } = this.state
        if (!dataList.some(path => path === value)) {
            const newList = [...dataList, value]

            this.setState({ dataList: newList })
            localStorage.setItem("devToolsDataList", JSON.stringify(newList))
        }
    }

    sendRequest = () => {
        this.setState({ loading: true, result: null, error: null })
        const { baseUrl, path, method, data } = this.state
        const url = path.charAt(0) !== "/" ? `${baseUrl}/${path}` : `${baseUrl}${path}`
        let reqData = {}

        if (method === "POST" || method === "PUT") {
            try {
                reqData = JSON.parse(data)
            } catch (error) {
                reqData = {}
            }
        }

        axios({
            method,
            url,
            data: reqData,
        })
            .then(res => {
                this.setState({ result: res.data })
                this.addToDataList(path)
            })
            .catch(error => {
                this.setState({ error })
            })
            .then(_ => this.setState({ loading: false }))
    }

    render() {
        const { path, baseUrl, method, loading, result, error, dataList, data } = this.state

        return (
            <Fragment>
                <Input
                    list="paths"
                    fluid
                    type='text'
                    placeholder='Path...'
                    action
                    value={path}
                    onChange={e => this.setState({ path: e.target.value })}
                    onKeyPress={e => e.key === 'Enter' && this.sendRequest()}
                >
                    <Select compact options={methodOptions} value={method} onChange={(_, { value }) => this.setState({ method: value })} />
                    <input />
                    <Select compact options={options} value={baseUrl} onChange={(_, { value }) => this.setState({ baseUrl: value })} />
                    <Button type='submit' onClick={this.sendRequest} loading={loading} disabled={loading} >
                        Send
                    </Button>
                </Input>
                <datalist id="paths" >
                    {dataList.map(path => <option key={path} value={path} />)}
                </datalist>
                {(method === "POST" || method === "PUT") &&
                    <Form>
                        <TextArea
                            autoHeight
                            style={{ marginTop: 16, width: '100%' }}
                            rows="3"
                            value={data}
                            onChange={e => this.setState({ data: e.target.value })}
                        />
                    </Form>
                }
                <div style={{ height: 16 }} />
                {result &&
                    <ReactJson src={result} theme="monokai" />
                }
                {error &&
                    <Fragment>
                        <pre>{JSON.stringify(error, null, 4)}</pre>
                        {/* <ReactJson src={error} theme="monokai" /> */}
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default DevTools
