import axios from 'axios';
import { getToken, isAuthenticated, deleteToken } from '../helpers/auth';

const getUrl = () => {
    const environment = localStorage.getItem("environment")

    if (environment) {
        if (environment === "local") return "http://localhost:3000"
        if (environment === "dev") return "https://api-dev.octaos.com"
        if (environment === "stg") return "https://api-stg.octaos.com"
        if (environment === "prod") return "https://api.octaos.com"
    } else {
        return "https://osc-api-srv-dev.azurewebsites.net"
    }
}

/**
 * Set api url
 */
let apiUrl = getUrl();

/**
 * Create an Axios Client with defaults
 */

const defaultValues = {};
defaultValues.baseURL = apiUrl;
// defaultValues.params = {};
defaultValues.headers = {
    'Content-Type': 'application/json',
};

const client = axios.create(defaultValues);

/**
 * Request Wrapper with default success/error actions
 */
const request = options => {

    client.defaults.baseURL = getUrl()

    if (isAuthenticated()) {
        client.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
    }
    // if (isAuthenticated()) client.defaults.params.token = getToken();

    const onSuccess = response => response;
    const onError = error => {
        if (
            error.response &&
            error.response.status === 401 &&
            (error.response.data.msgKey === 'token_expired' ||
                error.response.data.msgKey === 'invalid_token')
        ) {
            // handle expired token
            deleteToken()
            window.location.reload()
        }
        /* eslint-disable no-console */
        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);
        } else {
            // Something else happened while setting up the request
            // triggered the error
            console.error('Error Message:', error.message);
        }

        return Promise.reject(error.response || error.message);
    };

    return client(options)
        .then(onSuccess)
        .catch(onError);
};

export default request;
