import React, { Fragment, Component } from 'react'
import { Segment, Header, Button, Grid } from 'semantic-ui-react'

import { UserConsumer } from '../../../UserContext'
import {
    getUserAttributes,
    addUserAttribute,
    removeUserAttribute,
} from '../../../api/services/user'

import AddModal from '../components/AddModal'

class ExternalLinks extends Component {
    state = {
        vNumber: null,
        loading: false,
        externalLinks: [],
        editMode: false,
    }

    componentDidMount() {
        this.getUserAttributes()
    }

    filterAttributes = attr => attr.filter(att => att.attribute.substring(0, 13) === "externalLink_").map(link => {
        const name = link.attribute.substring(13)
        const values = link.value.split("|")

        return { name, url: values[0] }
    }).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase())

    getUserAttributes = () => {
        this.setState({ loading: true })
        const user = this.props.context
        getUserAttributes(user.id)
            .then(res => {
                const externalLinks = this.filterAttributes(res.data)
                this.setState({ externalLinks })
            })
            .catch(e => this.setState({ error: e }))
            .then(() => this.setState({ loading: false }))
    }

    handleSave = inputs => {
        this.setState({ loading: true })

        const { name, url } = inputs
        const attrName = `externalLink_${name}`
        const attrValue = `${url}`

        addUserAttribute(this.props.context.id, attrName, attrValue)
            .then(res => this.getUserAttributes())
            .catch(e => this.setState({ error: e }))
    }

    handleDelete = name => {
        this.setState({ loading: true })
        const attrName = `externalLink_${name}`

        removeUserAttribute(this.props.context.id, attrName)
            .then(res => this.getUserAttributes())
            .catch(e => this.setState({ error: e }))
    }

    render() {
        const { loading, externalLinks, editMode } = this.state
        const { hideMenu } = this.props

        return (
            <Fragment>
                {!hideMenu &&
                    <Fragment>
                        <Header
                            as="h2"
                            icon="firefox"
                            content="External Links"
                        />
                        <Segment basic clearing >
                            <Button toggle active={editMode} content="Edit" floated="right" onClick={() => this.setState({ editMode: !editMode })} />
                            <AddModal
                                header="external link"
                                fields={["name", "url",]}
                                onSave={this.handleSave}
                            />
                        </Segment>
                    </Fragment>
                }
                <Segment basic loading={loading} >
                    <Grid columns="equal" stackable >
                        {externalLinks.map(link =>
                            <Grid.Column key={`${link.name}${link.url}`} width="4" >
                                <Button basic fluid onClick={() => window.open(link.url, "_blank")} >
                                    <Header as="h3" textAlign="left" >
                                        {link.name}
                                        <Header.Subheader style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} >
                                            {link.url}
                                        </Header.Subheader>
                                    </Header>
                                </Button>
                                {editMode && <Button secondary basic icon="trash" floated="right" onClick={() => this.handleDelete(link.name)} />}
                            </Grid.Column>
                        )}
                    </Grid>
                </Segment>
            </Fragment>
        )
    }
}

const MapElement = props => <UserConsumer >{context => <ExternalLinks context={context} {...props} />}</UserConsumer>

export default MapElement
