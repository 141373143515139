import React, { Fragment, Component } from 'react'
import { Confirm, Button } from 'semantic-ui-react'

class ConfirmModal extends Component {
    state = {
        confirmOpen: false,
    }

    render() {
        const { confirmOpen } = this.state

        return (
            <Fragment>
                <Button secondary content="Delete" onClick={() => this.setState({ confirmOpen: true })} />
                <Confirm open={confirmOpen} onCancel={() => this.setState({ confirmOpen: false })} onConfirm={this.props.onConfirm} />
            </Fragment>
        )
    }
}

export default ConfirmModal
