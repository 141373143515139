import React, { Component } from 'react'

import { Segment, Image } from 'semantic-ui-react'

import Img1 from '../assets/OctaosArch1.png'
import Img2 from '../assets/OctaosArch2.png'
import Img3 from '../assets/OctaosArch3.png'
import Img4 from '../assets/OctaosArch4.png'
import Img5 from '../assets/OctaosArch5.png'
import Img6 from '../assets/OctaosArch6.png'

const getImage = i => {
    switch (i) {
        case 1:
            return Img1;
        case 2:
            return Img2;
        case 3:
            return Img3;
        case 4:
            return Img4;
        case 5:
            return Img5;
        case 6:
            return Img6;
        default:
            return Img6;
    }
}

class MyHeader extends Component {
    state = {
        image: 1
    }

    imageInterval

    componentDidMount() {
        const { loop } = this.props
        if (loop) {
            this.imageInterval = setInterval(() => {
                const { image } = this.state

                if (image === 6) this.setState({ image: 0 })
                else this.setState({ image: image + 1 })
            }, 100)
        } else {
            this.imageInterval = setInterval(() => {
                const { image } = this.state

                if (image === 6) clearInterval(this.imageInterval)
                else this.setState({ image: image + 1 })
            }, 250)
        }
    }

    componentWillUnmount() {
        clearInterval(this.imageInterval)
    }

    render() {
        const { image } = this.state

        return (
            <Segment basic textAlign="center" >
                <Image src={getImage(image)} centered size="tiny" />
                {/* <Header as="h1" >Octaos admin panel</Header> */}
            </Segment>
        )
    }
}

export default MyHeader
