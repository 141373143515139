import React, { Fragment, Component } from 'react'
import { Button, Modal, Form, Header, Message } from 'semantic-ui-react'
import moment from 'moment'

import { getReportInfo, getReport } from '../../../../api/services/report'

const initState = {
    modalOpen: false,
    reportInfo: null,
    loading: false,
    error: null,
    paramInputs: {},
    gettingReport: false,
    gettingReportError: null,
    dateFormat: "DD-MON-RR",
}

class AddModal extends Component {
    state = {
        ...initState
    }

    getReportInfo = () => {
        this.setState({ loading: true })
        getReportInfo(this.props.report.id)
            .then(res => this.setState({ reportInfo: res.data, paramInputs: this.initParamInputs(res.data) }))
            .catch(error => this.setState({ error }))
            .then(() => this.setState({ loading: false }))
    }

    getDateFormat = () => {
        const { dateFormat } = this.state
        const lower = dateFormat.toLowerCase()
        const replace = lower.replace(/r/g, "y").replace("mon", "mmm")

        return replace.toUpperCase()
        // return "DD.MM.YYYY"
    }

    getReport = async obj => {
        this.setState({ gettingReport: true, gettingReportError: null })
        const data = Object.keys(obj).map(key => {
            let value = obj[key]

            if (typeof value === "string" && value.split("-").length === 3) {
                value = moment(value).format(this.getDateFormat()).toUpperCase()
            }

            return ({ name: key, value })
        })

        try {
            const res = await getReport(this.props.report.id, data)

            this.setState({ gettingReport: false })

            if (res && res.data && res.data.file) {
                window.open(res.data.file, "_blank")
            }
        } catch (error) {
            let gettingReportError = error.data

            if (error.status && error.status === 404)
                gettingReportError = "Report not found"

            this.setState({ gettingReportError, gettingReport: false })
        }
    }

    initParamInputs = reportInfo => {
        let paramInputs = {}

        reportInfo.parameters.forEach(param => {
            if (param.type === "date") {
                this.setState({ dateFormat: param.format })
            }
            paramInputs[param.name] = param.value ? param.value : "";
        })

        return paramInputs
    }

    handleParamChange = (key, value) => this.setState({ paramInputs: { ...this.state.paramInputs, [key]: value } })

    handleOpen = () => {
        this.setState({ modalOpen: true })
        if (!this.state.reportInfo) {
            this.getReportInfo()
        }
    }

    handleClose = () => this.setState({ ...initState })

    render() {
        const { reportInfo, loading, error, modalOpen, paramInputs, gettingReport, gettingReportError } = this.state
        const { report } = this.props

        return (
            <Fragment>
                <Button primary content="Select" onClick={this.handleOpen} />
                <Modal open={modalOpen} onClose={this.handleClose} >
                    <Modal.Header>{report.name}</Modal.Header>
                    <Modal.Content>
                        {error
                            ?
                            <pre>{JSON.stringify(error, null, 4)}</pre>
                            :
                            <Form loading={loading} >
                                {loading && <div style={{ padding: 16 }} />}
                                {reportInfo && reportInfo.title.length <= 0 && <Header as="h2" textAlign="center" content="No info" />}
                                {reportInfo && reportInfo.title && <Header as="h2" content={reportInfo.title} />}
                                {reportInfo && reportInfo.parameters && reportInfo.parameters.map(param =>
                                    <Fragment key={param.name} >
                                        {param.type === "date"
                                            ?
                                            <Form.Input
                                                type="date"
                                                label={param.caption}
                                                value={paramInputs[param.name]}
                                                onChange={e => this.handleParamChange(param.name, e.target.value)}
                                            />
                                            : param.type === "text" ?
                                                <Form.Input
                                                    type="text"
                                                    label={param.caption}
                                                    value={paramInputs[param.name]}
                                                    onChange={e => this.handleParamChange(param.name, e.target.value)}
                                                />
                                                : param.type === "qveComboSQLValue" ?
                                                    <Form.Dropdown
                                                        scrolling
                                                        search
                                                        selection
                                                        label={param.caption}
                                                        placeholder={param.caption}
                                                        options={param.values.map(obj => ({ key: obj.value, text: obj.key, value: obj.value }))}
                                                        value={paramInputs[param.name]}
                                                        onChange={(_, { value }) => this.handleParamChange(param.name, value)}
                                                    />
                                                    : param.type === "select" ?
                                                        <Form.Checkbox
                                                            toggle
                                                            label={param.caption}
                                                            checked={paramInputs[param.name] === 1}
                                                            onChange={_ => this.handleParamChange(param.name, paramInputs[param.name] === 1 ? 0 : 1)}
                                                        />
                                                        :
                                                        <p style={{ color: "red" }} >No support for {param.type}</p>
                                        }
                                    </Fragment>
                                )}
                                {/* <pre>{JSON.stringify(reportInfo, null, 4)}</pre> */}
                            </Form>
                        }
                        {gettingReportError &&
                            <Message error>
                                <pre>{JSON.stringify(gettingReportError, null, 4)}</pre>
                            </Message>
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button content="Cancel" onClick={this.handleClose} />
                        <Button
                            disabled={gettingReport}
                            color="green"
                            content="Get"
                            loading={gettingReport}
                            onClick={() => {
                                this.getReport(paramInputs)
                                // window.alert(JSON.stringify(paramInputs, null, 4))
                                // this.props.onSave(reportName, selectedGuid, selectedReport)
                                // this.handleClose()
                            }} />
                    </Modal.Actions>
                </Modal>
            </Fragment>
        )
    }
}

export default AddModal
