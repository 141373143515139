import React, { Fragment, Component } from 'react'
import { Table, Dropdown } from 'semantic-ui-react'

import EditModal from '../components/EditModal'
import AddModal from '../components/AddModal'
import Page from '../components/Page'

class Users extends Component {
    state = {
        users: [{ id: 1, username: "Ola Norman", group: "System Admin", email: "ola@normann.no", enabled: 1, created: "2018-11-16" }],
        loading: false,
        filteredList: [],
        error: null,
    }

    componentDidMount() {
        this.getUsers()
    }

    getUsers = () => {
        this.setState({ filteredList: this.state.users })
    }

    filterList = text => {
        const { users } = this.state
        const filteredList = users.filter(user => {
            const username = user.username.substring(0, text.length).toLowerCase()

            return username === text.toLowerCase()
        })

        this.setState({ filteredList })
    }

    handleSave = (id, inputs) => {
        window.alert(JSON.stringify(inputs, null, 4))
    }

    render() {
        const { filteredList, loading, error } = this.state
        return (
            <Page
                icon="users"
                name="users"
                length={filteredList.length}
                loading={loading}
                error={error}
                onFilterChange={this.filterList}
                tableHeaders={["ID", "User name", "User group", "User email", "Enabled", "Created", ""]}
                tableBody={() => (
                    <Fragment>
                        {filteredList.map(user =>
                            <Table.Row key={user.id} >
                                <Table.Cell>{user.id}</Table.Cell>
                                <Table.Cell>{user.username}</Table.Cell>
                                <Table.Cell>{user.group}</Table.Cell>
                                <Table.Cell>{user.email}</Table.Cell>
                                <Table.Cell>{user.enabled === 1 ? "Yes" : "No"}</Table.Cell>
                                <Table.Cell>{user.created}</Table.Cell>
                                <Table.Cell collapsing>
                                    <EditModal
                                        object={user}
                                        header={user.username}
                                        onSave={inputs => this.handleSave(user.id, inputs)}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Fragment>
                )}
                extraFilters={() => (
                    <Dropdown placeholder="Filter user group..." multiple selection />
                )}
                addNew={() => (
                    <AddModal
                        header="user"
                        fields={["name",]}
                        onSave={() => window.alert("hmm")}
                    />
                )}
            />
        )
    }
}

export default Users

