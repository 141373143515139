import request from '../request';

export const getUser = () => request({
    url: '/user',
    method: 'GET',
});

export const getUserAttributes = id => request({
    url: `/user/${id}/attribute`,
    method: 'GET',
});

export const getUserAttribute = (id, attribute) => request({
    url: `/user/${id}/attribute/${attribute}`,
    method: 'GET',
});

export const addUserAttribute = (id, attribute, value) => request({
    url: `/user/${id}/attribute/${attribute}`,
    method: 'POST',
    data: { value }
});

export const updateUserAttribute = (id, attribute, value) => request({
    url: `/user/${id}/attribute/${attribute}`,
    method: 'PUT',
    data: { value }
});

export const removeUserAttribute = (id, attribute) => request({
    url: `/user/${id}/attribute/${attribute}`,
    method: 'DELETE',
});

export const userSearch = (q = null) => request({
    url: q ? `/user/search?q=${q}` : '/user/search',
    method: 'GET',
});
