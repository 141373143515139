import request from '../request';

export const login = body => request({
  url: '/auth/login',
  method: 'POST',
  headers: {},
  data: body,
});

export const logout = () => request({
  url: '/auth/logout',
  method: 'POST',
});

