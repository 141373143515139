import React, { Fragment, Component } from 'react'
import { Header, Segment, Table, Input, Grid, Message } from 'semantic-ui-react'

class Page extends Component {
    render() {
        const {
            icon,
            name,
            length,
            loading,
            error,
            onFilterChange,
            extraFilters,
            tableHeaders,
            tableBody,
            addNew,
        } = this.props
        return (
            <Fragment>
                <Header
                    as="h2"
                    icon={icon}
                    content={name.charAt(0).toUpperCase() + name.slice(1)}
                />
                {error && <Message error content={<pre>{JSON.stringify(error, null, 4)}</pre>} />}
                <Segment basic loading={loading} >
                    {loading ? <div style={{ height: 100 }} /> :
                        <Fragment>
                            <Grid columns="equal" stackable >
                                <Grid.Column>
                                    <Header as="h3" content={`${length} ${name} in list`} />
                                </Grid.Column>
                                {extraFilters &&
                                    <Grid.Column>{extraFilters()}</Grid.Column>
                                }
                                {onFilterChange &&
                                    <Grid.Column>
                                        <Input
                                            icon={icon}
                                            iconPosition="left"
                                            placeholder={`Search ${name}...`}
                                            onChange={e => onFilterChange(e.target.value)}
                                        />
                                    </Grid.Column>
                                }
                            </Grid>
                            <Table striped >
                                <Table.Header>
                                    <Table.Row>
                                        {tableHeaders.map(header =>
                                            <Table.HeaderCell key={header} >{header}</Table.HeaderCell>
                                        )}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {tableBody()}
                                </Table.Body>
                            </Table>
                            {addNew && addNew()}
                        </Fragment>
                    }
                </Segment>
            </Fragment>
        )
    }
}

export default Page
