import React, { Fragment, Component } from 'react'

import { Button, Form, Segment, Message, Header } from 'semantic-ui-react'

import { login } from '../api/services/auth'
import { getUser } from '../api/services/user'
import { setToken, deleteToken, isAuthenticated, getToken } from '../helpers/auth'
import MyHeader from './Header'

class Login extends Component {
    state = {
        username: "",
        password: "",
        loading: false,
        error: null,
        environment: localStorage.getItem("environment") ? localStorage.getItem("environment") : "local",
        loadingPreAuthenticated: false,
    }

    componentDidMount() {
        if (isAuthenticated()) {
            this.setState({ loadingPreAuthenticated: true })
            this.handleAuthenticated(getToken())
        }
    }

    handleAuthenticated = token => {
        setToken(token)
        getUser()
            .then(res => {
                if (res.data.groups[0].name === "system_admin") {
                    this.props.onAuthenticationChange(true, res.data)
                } else {
                    deleteToken()
                    this.props.onAuthenticationChange(false, {})
                }
            })
            .catch(e => this.setState({ error: e }))
            .then(() => this.setState({ loading: false, loadingPreAuthenticated: false }))
    }

    login = () => {
        const { username, password } = this.state

        localStorage.setItem("currentManufacturer", "Demo")

        this.setState({ loading: true, error: null })
        login({ username, password })
            .then(res => this.handleAuthenticated(res.data.token))
            .catch(e => this.setState({ error: e, loading: false }))
    }

    handleEnvironmentChange = name => {
        this.setState({ environment: name })
        localStorage.setItem("environment", name)
    }

    render() {
        const { username, password, loading, error, environment, loadingPreAuthenticated } = this.state

        return (
            <Fragment>
                {loadingPreAuthenticated
                    ?
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            width: 100,
                            height: 100,
                            marginTop: -50,
                            marginLeft: -50,
                        }}
                    >
                        <MyHeader loop />
                    </div>
                    :
                    <Fragment>
                        <MyHeader />
                        <Segment style={{ maxWidth: 500, margin: '50px auto' }} >
                            <Header as="h2" textAlign="center" >Sign in</Header>
                            <Form loading={loading} error={error} >
                                <Form.Input
                                    label="Username"
                                    type="text"
                                    value={username}
                                    onChange={e => this.setState({ username: e.target.value })}
                                />
                                <Form.Input
                                    label="Password"
                                    type="password"
                                    value={password}
                                    onChange={e => this.setState({ password: e.target.value })}
                                />
                                <Button primary fluid onClick={this.login} disabled={loading} >
                                    Sign in
                                </Button>
                                <Message error >
                                    <pre>{JSON.stringify(error, null, 4)}</pre>
                                </Message>
                            </Form>
                        </Segment>
                        <Segment basic textAlign="center" >
                            <Header as="h2" content="Environment" />
                            <Button.Group>
                                <Button toggle active={environment === "local"} content="Local" onClick={() => this.handleEnvironmentChange("local")} />
                                <Button toggle active={environment === "dev"} content="Dev" onClick={() => this.handleEnvironmentChange("dev")} />
                                <Button toggle active={environment === "stg"} content="Stg" onClick={() => this.handleEnvironmentChange("stg")} />
                                <Button toggle active={environment === "prod"} content="Prod" onClick={() => this.handleEnvironmentChange("prod")} />
                            </Button.Group>
                        </Segment>
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default Login
