import React, { Fragment, Component } from 'react';

import Login from './components/Login'
// import Header from './components/Header'
import Dashboard from './components/dashboard'

import { UserProvider } from './UserContext'

class App extends Component {
    state = {
        isAuthenticated: false,
        user: {}
    }

    render() {
        const { isAuthenticated, user } = this.state

        return (
            <div className="App">
                {isAuthenticated
                    ?
                    <UserProvider value={user} >
                        <Dashboard />
                    </UserProvider>
                    :
                    <Fragment>
                        <div style={{ height: 50 }} />
                        {/* <Header /> */}
                        <Login onAuthenticationChange={(isAuthenticated, user) => this.setState({ isAuthenticated, user })} />
                    </Fragment>
                }
            </div>
        );
    }
}

export default App;
