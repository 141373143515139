import React, { Fragment, Component } from 'react'
// import { Table, Button } from 'semantic-ui-react'

import AddModal from '../components/AddModal'
import Page from '../components/Page'

class ApiUsers extends Component {
    state = {
        users: [{ id: 1 }],
        filteredList: [{ id: 1 }],
        loading: false,
        error: null,
    }

    render() {
        const { filteredList, loading, error } = this.state

        return (
            <Page
                icon="globe"
                name="API Users"
                length={filteredList.length}
                loading={loading}
                error={error}
                tableHeaders={["ID", "API user", "Enabled", "Created", ""]}
                tableBody={() => (
                    <Fragment>
                        {/* {filteredList.map(report =>
                            <Table.Row key={report.id} >
                                <Table.Cell>{report.id}</Table.Cell>
                                <Table.Cell>{report.name}</Table.Cell>
                                <Table.Cell>{report.manufacturer}</Table.Cell>
                                <Table.Cell>{report.created}</Table.Cell>
                                <Table.Cell collapsing>
                                    <Fragment>
                                        <Button primary content="Select" />
                                        <Button color="black" content="Delete" />
                                    </Fragment>
                                </Table.Cell>
                            </Table.Row>
                        )} */}
                    </Fragment>
                )}
                addNew={() => (
                    <AddModal
                        header="API user"
                        fields={["name",]}
                        onSave={() => window.alert("hmm")}
                    />
                )}
            />
        )
    }
}

export default ApiUsers

